<template>
    <div class="login">
        <h1>绿树花旅后台</h1>
        <el-form :model="form" label-width="80px">
            <el-form-item label="账号">
                <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="form.password" type="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="login">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
        };
    },
    methods: {
        login() {
            this.$ajax
                .post(
                    `/user/login?username=${this.form.username}&password=${this.form.password}`
                )
                .then(res => {
                    localStorage.setItem('authToken', res.data);

                    this.$message.success('登录成功');
                    this.$router.replace('/');
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    width: 800px;
    margin: 50px auto 0 auto;
    h1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
    }
}
</style>
